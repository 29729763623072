export const renderJobPosting = () => {
  const message = [
    "%cHi 👋! Willkommen auf der %cdev side ⇆%c",
    "Wir arbeiten gerne mit React %c⚛%c,TypeScript %c{TS}%c und PHP %c🐘%c.",
    "Wie steht´s bei dir? Womit arbeitest du gerne, außer mit den dev tools auf der Suche nach Fehlern oder um schnell ein paar styles abzugreifen 😉?",
    "Wenn du bis hierher gelesen hast, schau dir doch auch mal unsere Stellenausschreibungen an 🥷 https://www.suora.com/jobs/",
  ].join("\n");

  const headingStyles = [
    "font-size: 24px",
    "font-family: monospace",
    "display: flex",
    "padding: 19px 0px 8px 0px",
  ].join(";");

  const standardLineStyles = [
    "font-size: 16px",
    "font-family: monospace",
    "display: flex",
    "padding: 8px 0px",
  ].join(";");

  const blueBigAndIcon = [
    "font-size: 24px",
    "font-family: monospace",
    "display: flex",
    "color: #128589",
  ].join(";");

  const blueNormalText = [
    "font-size: 16px",
    "font-family: monospace",
    "display: flex",
    "color: #128589",
  ].join(";");

  console.log(
    message,
    headingStyles,
    blueBigAndIcon,
    standardLineStyles,
    blueBigAndIcon,
    standardLineStyles,
    blueNormalText,
    standardLineStyles,
    blueBigAndIcon,
    standardLineStyles,
  );
};
